import React from "react";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Link from "./Link";
import { makeStyles, IconButton } from "@material-ui/core";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { useI18next } from "gatsby-plugin-react-i18next";
import { IconFlagUK, IconFlagDE } from "material-ui-flags";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles({
  list: {
    minWidth: 150,
    width: "auto",
    textAlign: "center",
  },
  listBox: {
    // textAlign: "center",
  },
  btn: {
    backgroundColor: "#31ade6",
    color: "white",
    "&:hover": {
      backgroundColor: "rgba(71, 184, 76, 1)",
    },
  },
  gridBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const getLangIcon = (type) => {
  switch (type) {
    case "de":
      return <IconFlagDE />;
    case "en":
      return <IconFlagUK />;
    default:
      return null;
  }
};

export default function MobileMenu({ state, setState, navigation }) {
  const { languages, changeLanguage } = useI18next();
  const { t } = useTranslation();
  const classes = useStyles();
  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState(open);
  };

  return (
    <SwipeableDrawer
      anchor="right"
      open={state}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
    >
      <div
        className={classes.list}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <List>
          {navigation.map(({ name, href, external }) => (
            <ListItem
              key={href}
              button
              component={Link}
              href={href}
              underline="none"
              external={external}
              className={classes.listBox}
            >
              {/* <ListItemIcon></ListItemIcon> */}
              <ListItemText primary={t(name)} />
            </ListItem>
          ))}
          <ListItem>
            <Grid container id="language" className={classes.language}>
              {languages.map((lng) => (
                <Grid key={lng} item xs={6} className={classes.gridBox}>
                  <IconButton
                    onClick={() => {
                      changeLanguage(lng);
                    }}
                    key={lng}
                  >
                    {getLangIcon(lng)}
                  </IconButton>
                </Grid>
              ))}
            </Grid>
          </ListItem>
        </List>
      </div>
    </SwipeableDrawer>
  );
}
