/** @format */

import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, IconButton, Button, Box } from "@material-ui/core";
import Link from "../components/Link";
import { AppBar } from "@material-ui/core";
import { useI18next } from "gatsby-plugin-react-i18next";
import { IconFlagUK, IconFlagDE } from "material-ui-flags";
import { StaticImage } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";
import MenuIcon from "@material-ui/icons/Menu";
import MobileMenu from "./MobileMenu";
import { useMediaQuery } from "@material-ui/core";
import { Toolbar } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "transparent",
    color: "#fff",
    paddingBottom: "1rem",
  },
  menuButton: {
    justifyContent: "center",
    textAlign: "center",
    marginTop: "1rem",
    marginBottom: "2rem",
  },
  title: {
    flexGrow: 1,
  },
  language: {
    borderBottom: "2px solid #fff",
    margin: "1rem",
  },
  logo: {
    marginLeft: "1rem",
    [theme.breakpoints.down("md")]: {
      width: 70,
    },
  },
  btn: {
    fontSize: "1em",
  },
  iconBox: {
    color: "#fff",
    marginRight: "2rem",
  },
  navButtons: {
    flexGrow: 1,
    textAlign: "left",
  },
  menuIcon: {
    fontSize: "40px",
  },
  btnBox: {
    textAlign: "center",
  },
}));

const navigation = [
  {
    name: "navbar.menuButton1",
    href: "/we",
    external: false,
  },
  {
    name: "navbar.menuButton2",
    href: "/#services",
    external: false,
  },
  {
    name: "navbar.menuButton3",
    href: "/success",
    external: false,
  },
  {
    name: "navbar.menuButton5",
    href: "https://m3e-gmbh.jobs.personio.de/",
    external: true,
  },
  {
    name: "navbar.menuButton4",
    href: "https://m3e.group/de/contact",
    external: true,
  },
];

const getLangIcon = (type) => {
  switch (type) {
    case "de":
      return <IconFlagDE />;
    case "en":
      return <IconFlagUK />;
    default:
      return null;
  }
};

export default function ButtonAppBar() {
  const { t } = useTranslation();

  const classes = useStyles();

  const { languages, changeLanguage } = useI18next();
  const antiMobile = useMediaQuery("@media (min-width:755px)");
  const [menu, setMenu] = useState(false);

  useEffect(() => {
    if (antiMobile) {
      setMenu(false);
    }
  }, [antiMobile]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" className={classes.root} elevation={0}>
        <div id="language" className={classes.language}>
          {languages.map((lng) => (
            <IconButton
              onClick={() => {
                changeLanguage(lng);
              }}
              key={lng}
              aria-label={lng === "de" ? "Deutsch" : "English"}
            >
              {getLangIcon(lng)}
            </IconButton>
          ))}
        </div>
        <Toolbar>
          <div className={classes.navButtons}>
            <Link href="/">
              <StaticImage
                width={100}
                src="../media/nav/m3eAgencyLogo.png"
                alt="M3E"
                placeholder="none"
                formats={["auto", "webp", "avif"]}
                className={classes.logo}
              />
            </Link>
          </div>
          {antiMobile ? (
            <Grid item xs={12} className={classes.btnBox}>
              {navigation.map(({ name, href, external }) => (
                <Button
                  key={href}
                  component={Link}
                  href={href}
                  external={external}
                  color="inherit"
                >
                  {t(name)}
                </Button>
              ))}
            </Grid>
          ) : (
            <IconButton
              className={classes.iconBox}
              edge="start"
              aria-label="menu"
              onClick={() => setMenu(!menu)}
            >
              <MenuIcon className={classes.menuIcon} />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      {!antiMobile && (
        <MobileMenu state={menu} setState={setMenu} navigation={navigation} />
      )}
    </Box>
  );
}

/* <Button
              className={classes.btn}
              id="btnWe"
              color="inherit"
              component={Link}
              to="/#we">
              {t("navbar.menuButton1")}
            </Button>
            <Button
              className={classes.btn}
              id="btnOurServices"
              color="inherit"
              component={Link}
              to="/#services">
              {t("navbar.menuButton2")}
            </Button>
            <Button
              className={classes.btn}
              id="btnOurWork"
              color="inherit"
              component={Link}
              to="/#work">
              {t("navbar.menuButton3")}
            </Button>
            <Button
              className={classes.btn}
              id="btnContact"
              color="inherit"
              component={Link}
              to="/contact">
              {t("navbar.menuButton4")}
            </Button> */
