import React from "react";
import AppBar from "@material-ui/core/AppBar";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, IconButton, Typography } from "@material-ui/core";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
import { Button } from "@material-ui/core";
import Link from "../components/Link";
import { StaticImage } from "gatsby-plugin-image";
import { Box } from "@material-ui/core";
import { useTranslation } from "gatsby-plugin-react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    // background: "#23d5ab",
    background: "rgba(255, 255, 255, 0.2)",
    width: "100%",
    paddingBottom: "2rem",
    marginTop: "2rem",
  },
  logoBox: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
  },
  iconButtonBox: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    color: "white",
  },
  btn: {
    color: "white",
    paddingTop: "0!important",
    paddingBottom: "0!important",
  },
  btnBox: {
    marginTop: "0.5rem",
  },
  gridBox: {
    // borderTop: "2px solid #fff",

    paddingTop: "1rem",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  box: {
    textAlign: "start",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  subpoint: {
    marginTop: "1rem",
  },
  btnCorporate: {
    color: "#fff",
  },
  typo: {
    marginTop: "1rem",
    marginBottom: "1rem",
    paddingLeft: "0.5rem",
  },
  text: {
    paddingLeft: "0.5rem",
    textDecoration: "none",
    color: "#fff",
  },
  buttonLabel: {
    justifyContent: "flex-start",
  },
}));

const socials = [
  // {
  //   Icon: TwitterIcon,
  //   href: "M3E GmbH (@M3EGmbh) / Twitter",
  //   ariaLabel: "Twitter",
  // },
  {
    Icon: LinkedInIcon,
    href: "https://www.linkedin.com/company/m3e-communications/",
    ariaLabel: "LinkedIn",
  },
];

export default function ProminentAppBar() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      <AppBar position="static" className={classes.root} elevation={4}>
        <Grid container id="container">
          <Grid item xs={12} className={classes.logoBox}>
            <Box my={3}>
              <Link href="/">
                <StaticImage
                  src="../media/nav/m3eAgencyLogo.png"
                  width={100}
                  alt="M3E"
                  placeholder="none"
                  formats={["auto", "webp", "avif"]}
                />
              </Link>
            </Box>
          </Grid>
          <Grid container justifyContent="center" className={classes.gridBox}>
            <Grid item xs={12} md={3} className={classes.box}>
              <Typography variant="h4" className={classes.typo}>
                {t("footer.footerHallo")}
              </Typography>
              <Typography
                variant="body1"
                // className={classes.subpoint}
                className={classes.text}
              >
                +49 (0) 30 403672121
              </Typography>
              <Typography
                variant="body1"
                // className={classes.subpoint}
                className={classes.text}
              >
                pr[at]m3e-communications.com
              </Typography>

              <Grid item xs={12} className={classes.btnBox}>
                <Button
                  className={classes.btn}
                  component={"a"}
                  href="https://m3e-gmbh.jobs.personio.de/"
                  target="_blank"
                  rel="noopener roreferrer"
                  classes={{ label: classes.buttonLabel }}
                >
                  {t("footer.footerButtonJobs")}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  className={classes.btn}
                  component={"a"}
                  href="https://m3e.group/contact"
                  target="_blank"
                  rel="noopener roreferrer"
                >
                  {t("footer.footerButtonContact")}
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography component="p" variant="h4" className={classes.typo}>
                {t("footer.footerAdresse")}
              </Typography>
              <Typography variant="body1" className={classes.text}>
                Torstraße 23
              </Typography>
              <Typography variant="body1" className={classes.text}>
                10119 Berlin
              </Typography>
              <Grid item xs={12} className={classes.btnBox}>
                <Button
                  className={classes.btn}
                  component={Link}
                  href="/imprint"
                >
                  {t("footer.footerButtonImpressum")}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  className={classes.btn}
                  component={Link}
                  href="/privacy"
                >
                  {t("footer.footerButtonDatenschutz")}
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="h4" className={classes.typo}>
                {t("footer.footerFolgeUns")}
              </Typography>
              {socials.map(({ Icon, href, ariaLabel }) => (
                <IconButton
                  key={href}
                  component="a"
                  aria-label={ariaLabel}
                  href={href}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon className={classes.icon} />
                </IconButton>
              ))}
              <Button
                component={Link}
                external
                href="https://m3e-gmbh.com/"
                className={classes.btnCorporate}
              >
                M3E Group
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </AppBar>
    </div>
  );
}
