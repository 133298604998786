import React, { forwardRef } from "react";
import { Link as InternalLink } from "gatsby";
import { useTranslation } from "react-i18next";

const Link = forwardRef(({ external, children, href, className }, ref) => {
  const { i18n } = useTranslation();
  const localeHref = i18n.language !== "de" ? `/${i18n.language}${href}` : href;

  return external ? (
    <a
      ref={ref}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className={className}
    >
      {children}
    </a>
  ) : (
    <InternalLink ref={ref} to={localeHref} className={className}>
      {children}
    </InternalLink>
  );
});

export default Link;
